<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Thông tin tài khoản</h4>
        <div class="content-inner__body-form">
          <ValidationObserver ref="form">
            <form @keyup.enter="handleUpdateUser">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-4">
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    id="floatingUsername"
                    placeholder="name@example.com"
                  />
                  <label for="floatingUsername">Tên hiển thị</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-4">
                  <input
                    v-model="form.identityNumber"
                    type="text"
                    class="form-control"
                    id="floatingIdentity"
                    placeholder="name@example.com"
                  />
                  <label for="floatingIdentity">CMND/CCCD</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-4">
                  <input
                    v-model="form.phone"
                    type="text"
                    class="form-control"
                    id="floatingPhone"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPhone">Số điện thoại</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-4">
                  <input
                    v-model="form.address"
                    type="text"
                    class="form-control"
                    id="floatingAddress"
                    placeholder="name@example.com"
                  />
                  <label for="floatingAddress">Địa chỉ</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="btn btn-primary mb-4"
            @click="handleUpdateUser"
          >
            XÁC NHẬN
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <b-modal
      v-model="showModalSuccess"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <h5 class="fw600">Cập nhật thông tin thành công</h5>
        <span class="f14 text-second-color">
          Thông tin của bạn đã được thay đổi.
        </span>
      </div>
      <b-button variant="primary" class="mt-3 w-100" @click="handleConfirm">
        Xác nhận
      </b-button>
    </b-modal>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      token: this.$cookies.get("token"),
      loading: {
        isLoading: false,
        fullPage: true,
      },
      form: {},
      showModalSuccess: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading.isLoading = true;
        const response = await UserService.getUser(this.token);
        if (response.code === "SUCCESS") {
          this.form = response.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
    handleUpdateUser() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const { name, identityNumber, phone, address, dob } = this.form;
            const data = {
              name,
              identityNumber,
              phone,
              address,
              dob,
            };
            this.loading.isLoading = true;
            const response = await UserService.updateUser(this.token, data);
            if (response.code === "SUCCESS") {
              this.showModalSuccess = true;
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
    handleConfirm() {
      const { accountRedirectUri } = this.$route.query;
      if (accountRedirectUri) {
        window.location.href = accountRedirectUri;
      } else {
        this.showModalSuccess = false;
      }
    },
  },
};
</script>
